<template>
  <admin-default v-slot:admin>
    <div class="application" >
      <div class="container container-resize">
        <div class="row border-bottom pb-5 pl-4">
          <div class="col-md-9"><h1 class="application__secondary--title">Loan Application</h1></div>
          <div class="col-6 col-md-3">
            <h1 class="application__main--title">Welcome,</h1>
            <h1 class="application__tertiary--title">{{ user.name }}</h1>
          </div>
        </div>
      </div>
      <!--NAV-->
      <div class="containe gcontainer-resize mr-2 ml-2">
        <div class="row">
          <div class="col-12">
            <a  class="btn button button-cta cta pt-2 float-right" @click.prevent="ApplyForLoan" >
              Apply for Loan
            </a>
          </div>
        </div>
        <application-loan-tables></application-loan-tables>
      </div>
    </div>
  </admin-default>
</template>

<script>
import {mapGetters} from 'vuex'
import AdminDefault from "../navigate/AdminDefault";
import ApplicationLoanTables from "./pages/ApplicationLoanTables";
export default {
  name: "LoanApplication",
  components: {ApplicationLoanTables, AdminDefault},
  computed: {
    ...mapGetters({
      loans: 'loan/loans'
    })
  },
  methods: {
    ApplyForLoan () {
      let reject = null
      reject = this.loans.find(element => element.status !== 'paid')
      if (reject) {
        this.$toastr.e('You still have a running application, you cannot apply for new loans')
        return false
      }
      this.$router.push('/loan-application-form')
    }
  }
}
</script>

<style scoped>

</style>