<template>
<div class="row mt-5 ">
  <div class="col-md-12 mb-5" style="overflow: scroll">
    <b-table striped hover
             :items="applications" id="table"
             :fields="fields"
             :per-page="perPage"
             selectable
             :current-page="currentPage"
             class="size-table">
      <template #cell(index)="data">
        <div class="form-checksss cks">
          <label >
            {{data.index + 1 }}
          </label>
        </div>
      </template>
      <template #cell(date_expiry)="data">
        <p>{{ new Date(data.item.date_expiry).toDateString()}}</p>
      </template>
      <template #cell(loan_credit)="data">
        <p>{{ formatters(data.item.loan_credit) }}</p>
      </template>
      <template #cell(data_placement)="data">
        <p>{{ new Date(data.item.data_placement).toDateString() }}</p>
      </template>
      <template #cell(monthlypayment)="data">
        <p>{{ formatters(data.item.monthlypayment) }}</p>
      </template>
      <template #cell(state)="data">
        <span v-if="data.item.state === 'paid'" style="color: #5cb85c">Fully Paid</span>
        <span v-if="data.item.state === 'running'" style="color: aqua">Running</span>
        <span v-if="data.item.state === 'ready'" style="color: #49002C">Ready For approval</span>
        <span v-if="data.item.state === 'draft'" style="color: #d3394c">Pending Approval</span>
        <span v-if="data.item.state === 'eliminate'" style="color: #980303">Eliminated Approval</span>
        <span v-if="data.item.state === 'approve'" style="color: blueviolet">Approved for Disbursement</span>
        <span v-if="data.item.state === 'reject'" style="color: #d3394c">Loan Denied</span>
      </template>
    </b-table>
    <div class="rows">
      <div class="col-md-12">
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table"
        ></b-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "ApplicationLoanTables",
  data () {
    return {
      perPage: 29,
      currentPage: 1,
      fields: [
        {
          key: 'index', label: 'S/N'
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'loan_credit',
          label: 'Amount Requested',
        },
        {
          key: 'date_expiry',
          label: 'Expired Date',
        },
        {
          key: 'data_placement',
          label: 'Date Requested',
        },
        {
          key: 'mobile',
          label: 'Phone Number',
        },
        {
          key: 'state',
          label: 'Status',
        },
      ]
    }
  },
  methods: {},
  computed:{
    ...mapGetters({
      user: 'auth/user',
      application: 'loan/loan_application',
    }),

    applications () {
      return this.sortByRecentCreatedItems(this.application)
    },
    rows() {
      console.log(this.applications)
      return this.applications.length
    }
  },
}
</script>

<style scoped>

</style>